<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <van-collapse-item :title="$t('pur.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('pur.收货指令号') }}</h5>
          <p>{{ this.ruleForm.harvestInstructionNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.申请日期') }}</h5>
          <p>{{ this.ruleForm.applicationDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.状态') }}</h5>
          <p>{{ this.ruleForm.orderStatus }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购订单号') }}</h5>
          <p>{{ this.ruleForm.purContNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购合同号') }}</h5>
          <p>{{ this.ruleForm.poHeaderNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商') }}</h5>
          <p>{{ this.ruleForm.supplier }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.销售订单号') }}</h5>
          <p>{{ this.ruleForm.saleContractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.销售合同号') }}</h5>
          <p>{{ this.ruleForm.salePoHeaderNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.二级代理商') }}</h5>
          <p>{{ this.ruleForm.secondaryAgent }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.收货方式') }}</h5>
          <p>{{ this.ruleForm.receivingWay }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户') }}</h5>
          <p>{{ this.ruleForm.finalUser }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.下单日期') }}</h5>
          <p>{{ this.ruleForm.orderDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.总箱量') }}</h5>
          <p>{{ this.ruleForm.totalVolume }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.产品数量') }}</h5>
          <p>{{ this.ruleForm.quantity }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否需要报关') }}</h5>
          <p>{{ this.ruleForm.isCustomsClearance }}</p>
        </div>
        <div class="items" v-show="ruleForm.isCustomsClearance === 'Y' && (ruleForm.orderStatus === '1')">
          <h5>{{ $t('pur.报关公司') }}</h5>
          <p>{{ this.ruleForm.customsBrokerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.备注') }}</h5>
          <p>{{ this.ruleForm.remarks }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.收货信息')" name="2">
        <div class="items" v-show="ruleForm.receivingWay === '1' || ruleForm.receivingWay === '3'">
          <h5>{{ $t('pur.联系人') }}</h5>
          <p>{{ this.ruleForm.contact }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.联系人电话') }}</h5>
          <p>{{ this.ruleForm.contactNumber }}</p>
        </div>
        <div class="items" v-show="ruleForm.receivingWay === '1'">
          <h5>{{ $t('pur.存货仓库') }}</h5>
          <p>{{ this.ruleForm.warehouseName }}</p>
        </div>
        <div class="items" v-show="ruleForm.receivingWay === '3'">
          <h5>{{ $t('pur.寄存仓库') }}</h5>
          <p>{{ this.ruleForm.warehouseName }}</p>
        </div>
        <div class="items" v-show="ruleForm.receivingWay === '1' || ruleForm.receivingWay === '3'">
          <h5>{{ $t('pur.提货地址') }}</h5>
          <p>{{ this.ruleForm.deliveryAddress }}</p>
        </div>
        <div class="items" v-show="ruleForm.receivingWay === '3'">
          <h5>{{ $t('pur.寄存仓库地址') }}</h5>
          <p>{{ this.ruleForm.warehouseAddress }}</p>
        </div>
        <div class="items" v-show="ruleForm.receivingWay === '2'">
          <h5>{{ $t('pur.收货仓库') }}</h5>
          <p>{{ this.ruleForm.warehouseName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.预计到货日期') }}</h5>
          <p>{{ this.ruleForm.harvestInstructionDate }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.收货产品明细')" name="3">
        <div v-for="(item, index) in ruleForm.lineList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.采购订单号') }}:</h5>
            <p>{{ item.poHeaderNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品编号') }}:</h5>
            <p>{{ item.materialCode }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品名称') }}:</h5>
            <p>{{ item.commodityDetail }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类别') }}:</h5>
            <p>{{ item.commodityCategory }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.规格型号') }}:</h5>
            <p>{{ item.model }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品组') }}:</h5>
            <p>{{ item.productGroup | setDict('productGroup')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品线') }}:</h5>
            <p>{{ item.productLine | setDict('PRODUCTLINE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类型') }}:</h5>
            <p>{{ item.productType | setDict('PRODUCT_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类型') }}-二级分类:</h5>
            <p>{{ item.secondaryProductType | setDict('PRODUCT_SECOND_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.数量') }}:</h5>
            <p>{{ item.quantity }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.单位') }}:</h5>
            <p>{{ item.unit }}</p>
          </div>
          <div class="items">
            <h5>SN:</h5>
            <p>{{ item.sn }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.是否有实物') }}:</h5>
            <p>{{ item.isPhysical | setDict('PUB_Y_N')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.箱数') }}:</h5>
            <p>{{ item.containerNumber }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.体积') }}:</h5>
            <p>{{ item.volume }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.重量') }}:</h5>
            <p>{{ item.weight }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.备注') }}:</h5>
            <p>{{ item.remarks }}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.系统信息')" name="4">
        <div class="items">
          <h5>{{ $t('pur.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
  import { Dialog } from 'vant';
  import util from '../../../libs/util';
  import myHistoryPanel from '@/views/business/components/myHistoryPanel';
  import myApproval from '@/views/business/components/myApproval';

  export default {
    name: 'visitorFieldComponent',
    components: {
      myHistoryPanel,
      myApproval
    },
    data() {
      return {
        loading: false,
        finished: false,
        activeNames: ['1', '2', '3', '4'],
        hostName: '',
        task: '',
        personId: '',
        approvalShow: false,
        customSubmitBtnName: ['同意', '不同意'],
        id: '',
        key: '',
        title: '',
        detail: null,
        active: 0,
        businessKey: '',
        woCode: 'purHarvestInstruction',
        processInstanceName: '收货指令',
        mappingId: '',
        taskInstanceId: '',
        currentNode: '',
        variables: {},
        taskType: '',
        ruleForm: {
          id: '',
          harvestInstructionNo: '',
          harvestInstructionDate: '',
          purContId: '',
          purContNo: '',
          supplierId: '',
          supplier: '',
          businessType: '',
          totalVolume: '',
          quantity: '',
          receivingWay: '',
          contact: '',
          contactId: '',
          contactNumber: '',
          deliveryAddress: '',
          warehouseId: '',
          warehouseCode: '',
          warehouseName: '',
          warehouseAddress: '',
          applicationDate: '',
          poHeaderNo: '',
          orderDate: '',
          saleContractId: '',
          saleContractNo: '',
          salePoHeaderNo: '',
          secondaryAgentId: '',
          secondaryAgent: '',
          finalUserId: '',
          finalUser: '',
          remarks: '',
          orderStatus: '',
          receivingState: '',
          createBy: '',
          createTime: '',
          lastmodifiedTime: '',
          createByName: '',
          isCustomsClearance: '',
          customsBrokerName: '',
          customsBrokerId: '',
          lineList: []
        },
      };
    },
    props: {},
    methods: {
      getProcessMappingId() {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.$myHttp({
          method: 'post',
          url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
          data: {
            entity: {
              woCode: this.woCode,
              orgId: userInfo.companyId,
              deptId: userInfo.deptId
            }
          }
        }).then(res => {
          if (res.data.ext.code === '2000') {
            this.mappingId = res.data.ext.mappingId;
          } else {
            Toast.fail({
              message: res.data.ext.message,
              type: 'error',
              duration: 1500
            });
          }
        });
      },
      backfilleditData(id) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/pur/purHarvestInstruction/view',
          data: {
            entity: {
              id: id
            }
          }
        }).then(res => {
          // 成功回调方法
          var data = res.data.entity;
          this.ruleForm = data;
          this.ruleForm.orderStatus = util.setDict(this.ruleForm.orderStatus, 'orderStatus');
          this.ruleForm.receivingWay = util.setDict(this.ruleForm.receivingWay, 'RECEIVING_WAY');
          this.ruleForm.isCustomsClearance = util.setDict(this.ruleForm.isCustomsClearance, 'PUB_Y_N');
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.harvestInstructionNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        });
      },
      setApprovalData(row) {
        if (row && row.pid) {
          this.ruleForm.id = row.businessKey;
          this.taskInstanceId = row.id;
          this.taskType = row.taskType;
        }
      },
      /**
       * 业务提交之前校验
       * */
      checkBeforeProcess(type) {
        if (type) {
          if (type === 'beforeAddsign') {
            this.$refs.demoTemperatureEdit.beforeAddsign();
          } else if (type === 'afterAddsign') {
            this.$refs.demoTemperatureEdit.afterAddsign();
          } else if (type === 'endProcess') {
            this.$refs.demoTemperatureEdit.endProcess();
          } else if (type === 'repelProcess') {
            this.repelProcess();
          } else {
            if (!this.taskInstanceId) {
              var params = {
                businessKey: this.ruleForm.id,
                mappingId: this.mappingId,
                processInstanceName: this.processInstanceName,
                userName: sessionStorage.getItem('userName'),
                variables: this.variables
              };
              this.startProcessAction(params);
            } else {
              this.$refs.demoTemperatureEdit.submit();
            }
          }
        } else {
          Toast.fail({
            message: this.$t('pur.数据校验失败'),
            type: 'warning'
          });
        }
      },
      // 业务发起流程
      startProcessAction(params) {
        if (params.mappingId) {
          params.mappingId = this.mappingId;
        }
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.harvestInstructionNo, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/pur/purHarvestInstruction/start',
          data: params
        })
          .then(res => {
            var backData = res.data.ext;
            if (backData) {
              this.$refs.demoTemperatureEdit.submitLoading = false;
              this.$refs.demoTemperatureEdit.isStart = true;
              this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
              this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
              this.$refs.demoTemperatureEdit.isFirstNode = true;
              this.$refs.demoTemperatureEdit.taskId = backData.taskId;
              this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
              this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
            } else {
              Toast.fail({
                message: this.$t('pur.流程发起异常'),
                type: 'warning'
              });
              this.$refs.demoTemperatureEdit.submitLoading = false;
            }
          })
          .catch(_ => {
            this.$refs.demoTemperatureEdit.submitLoading = false;
          });
      },
      // 业务提交流程
      submitProcessAction(params) {
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.harvestInstructionNo, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/pur/purHarvestInstruction/submit',
          data: params
        })
          .then(res => {
            if (res.data.ext.success) {
              this.processCallback(null, null, this.taskType);
            } else {
              Toast.fail({
                message: res.data.ext.msg,
                type: 'warning'
              });
            }
          })
          .catch(_ => {});
      },
      /***
       * 业务撤销流程
       */
      repelProcess() {
        var _this = this;
        _this
          .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
            cancelButtonClass: 'sysBackBtn'
          })
          .then(_ => {
            this.$myHttp({
              method: 'post',
              url: '/microarch/pur/purHarvestInstruction/undoProcess',
              data: {
                entity: {
                  id: this.ruleForm.id,
                  processInstanceId: this.ruleForm.processInstanceId
                }
              }
            })
              .then(res => {
                if (res.data.success === undefined || res.data.success) {
                  Toast.fail({
                    message: this.$t('pur.撤销成功'),
                    type: 'success'
                  });
                  this.processCallback(null, null, this.taskType);
                } else {
                  Toast.fail({
                    message: res.data.msg,
                    type: 'warning'
                  });
                }
              })
              .catch(_ => {});
          })
          .catch(_ => {});
      },

      /***
       * 关闭发起流程审批窗口
       * 删除流程实例，回滚业务状态
       */
      closeStartCallback(pid) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/pur/purHarvestInstruction/deleteProcess',
          data: {
            entity: {
              id: this.ruleForm.id,
              processInstanceId: pid
            }
          }
        })
          .then(res => {
            this.$refs.demoTemperatureEdit.dialogVisible = false;
            this.$refs.demoTemperatureEdit.taskId = '';
            this.$refs.demoTemperatureEdit.processInstanceId = '';
            this.processCallback(null, null, this.taskType);
          })
          .catch(_ => {});
      },

      /**
       * 提交之前校验
       * */
      beforeSubmitCallBack(params) {
        // 业务参数校验 此时可以从params中获取提交审批所有的参数
        // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
        // 不通过给出相应的提示或者触发其他业务操作
        // let selectUserArray = params.candidateUserIds[params.nextId]
        // console.log(selectUserArray);
        this.submitProcessAction(params);
      },
      /**
       * 流程审批之后业务数据更改
       * */
      processCallback(piid, optionType, taskType) {
        var _this = this;
        // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
        // // 成功回调方法
        // if (taskType === 'transated') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/haveList'
        //   });
        // } else if (taskType === 'waitfor') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/toDoList'
        //   });
        // } else {
        //   this.$router.push({
        //     name: 'sale/return/saleReturnApplyList'
        //   });
        //   this.$bus.emit('saleReturnApply-SaveSuccess');
        // }
        this.$router.go(-1);
      }
    },
    created() {},
    mounted() {
      let selectRow = JSON.parse(this.$route.query.row);
      if (selectRow) {
        this.ruleForm.id = selectRow.id;
        if (selectRow.businessKey) {
          this.ruleForm.id = selectRow.businessKey;
        }
      }
      if (this.ruleForm.id) {
        this.backfilleditData(this.ruleForm.id);
      }

      if (selectRow) {
        this.setApprovalData(selectRow);
      }
    },
    filters: {
      setDict(v, dictName) {
        return util.setDict(v, dictName);
      }
    }
  };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
